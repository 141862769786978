/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
// import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'buttons',
    items: [
      {
        title: 'Home',
        // icon: PieChartIcon,
        href: '/home'
      },
      {
        title: 'About Us',
        // icon: GridIcon,
        href: '/about-us'
      },
      {
        title: 'Articles',
        // icon: GridIcon,
        href: '/articles'
      },
      {
        title: 'Full Inventory',
        // icon: GridIcon,
        href: '/get-started'
      },
      {
        title: 'Procurement Services',
        // icon: GridIcon,
        href: '/procurement-services'
      },
      {
        title: 'O&M Procurement',
        // icon: GridIcon,
        href: '/o-m-procurement'
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding >
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 200
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    width: 100,
    height: 100,
    backgroundColor:"#1c2025"
  },
  blueUnderlineMobile: {
    textDecoration: 'none',
    borderBottom: '1px solid #0cb3e5',
    display: 'inline',
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "34px",
    height: "43px"
  },
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Logo className = {classes.avatar} /> */}
        <Box p={1} display='flex' justifyContent='center'>
        <Avatar className={classes.avatar}>
          <Box style={{marginTop:"-13px"}}>
            <Box display='flex' justifyContent='center' >
              <Typography
                color="textPrimary"
                className={classes.blueUnderlineMobile}
              >
                1000
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center' >
              <Typography
                color="textPrimary"
                style={{ textAlign: "center", fontWeight:"normal", fontSize:"9px", marginTop:"2px"}}
              >
                CONTAINERS.COM
              </Typography>
            </Box>
          </Box>
        </Avatar>
        </Box>


        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      {/* <Hidden smDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden> */}
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
