import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { THEMES } from 'src/constants';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    maxHeight: 30,
    position: "relative"
  },
  navButtons: {
    fontSize: "15px",
    margin: "0px",
    marginLeft: "25px",
    textTransform: "none",
    borderRadius: 0
  },
  navButtonsActive: {
    fontSize: "15px",
    margin: "0px",
    marginLeft: "25px",
    textTransform: "none",
    color: "rgb(12, 179, 229)",
    borderRadius: "0px",
    borderBottom: "solid 2px rgb(12, 179, 229)",
    paddingBottom: "0px"
  },
  navButtonsActiveMobile: {
    fontSize: "17px",
    margin: "0px",
    textTransform: "none",
    color: "rgb(12, 179, 229)"
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [pathname, setPathName] = useState(null);

  useEffect(() => {
    if (location.pathname) {
      setPathName(location.pathname);
    }
  }, [location]);

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
          <IconButton
            onClick={onMobileNavOpen}
            style={{
              position: 'absolute',
              left: 15,
            }}
          >
            <SvgIcon fontSize="small">
              <MenuIcon color='white' />
            </SvgIcon>
          </IconButton>
          {/* </Hidden>

        <Hidden mdUp> */}
          <Box display="flex" justifyContent="center" width="100%">
            {(pathname === "/home") &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                Home
              </Button>
            }
            {(pathname === "/about-us") &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                About Us
              </Button>
            }
            {(pathname === "/articles") &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                Articles
              </Button>
            }
            {(pathname === "/login") &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                Login
              </Button>
            }
            {(pathname === "/register") &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                Create Account
              </Button>
            }
            {(pathname === "/forgot-pasword") &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                Reset Password
              </Button>
            }
            {(pathname === "/guest/login") &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                Guest Login
              </Button>
            }
            {((pathname === "/get-started")) &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                Full Inventory
              </Button>
            }
            {(pathname === "/procurement-services") &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                Procurement Services
              </Button>
            }
            {(pathname === "/o-m-procurement") &&
              <Button size='small' className={classes.navButtonsActiveMobile}>
                O&M Procurement
              </Button>
            }
          </Box>
        </Hidden>

        <Hidden smDown>
          <Button size='small' className={(pathname === "/home") ? classes.navButtonsActive : classes.navButtons} onClick={() => history.push("/home")}>
            Home
          </Button>
          <Button size='small' className={(pathname === "/about-us") ? classes.navButtonsActive : classes.navButtons} onClick={() => history.push("/about-us")}>
            About Us
          </Button>
          <Button size='small' className={(pathname === "/articles") ? classes.navButtonsActive : classes.navButtons} onClick={() => history.push("/articles")}>
            Articles
          </Button>
          <Button size='small' className={(pathname === "/get-started" || pathname === "/login" || pathname === "/register" || pathname === "/guest/login" || pathname === "/forgot-password") ? classes.navButtonsActive : classes.navButtons} onClick={() => history.push("/get-started")}>
            Full Inventory
          </Button>
          <Button size='small' className={(pathname === "/procurement-services") ? classes.navButtonsActive : classes.navButtons} onClick={() => history.push("/procurement-services")}>
            Procurement Services
          </Button>
          <Button size='small' className={(pathname === "/o-m-procurement") ? classes.navButtonsActive : classes.navButtons} onClick={() => history.push("/o-m-procurement")}>
            O&M Procurement
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
