/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Search as SearchIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Grid as GridIcon,
  Database as DatabaseIcon
} from 'react-feather';
// import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Admin Dashboard',
        icon: PieChartIcon,
        href: '/app/admin/interface/home'
      },
      {
        title: 'Hidden Data',
        icon: GridIcon,
        href: '/app/admin/hiddenData/list'
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Edit products',
        icon: DatabaseIcon,
        href: '/app/admin/allproducts/list'
      },
      {
        title: 'Search Results',
        icon: SearchIcon,
        href: '/app/admin/saveSearch/results'
      },
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/app/admin/customers/list',
        // items: [
        //   {
        //     title: 'List Users',
        //     href: '/app/management/customers'
        //   },
        //   {
        //     title: 'View Customer',
        //     href: '/app/management/customers/1'
        //   },
        //   {
        //     title: 'Edit Customer',
        //     href: '/app/management/customers/1/edit'
        //   }
        // ]
      }

    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    width: 100,
    height: 100,
    backgroundColor:"#1c2025"
  },
  blueUnderlineMobile: {
    textDecoration: 'none',
    borderBottom: '1px solid #0cb3e5',
    display: 'inline',
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "34px",
    height: "43px"
  },
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Box p={1} display='flex' justifyContent='center'>
        <Avatar className={classes.avatar}>
          <Box style={{marginTop:"-13px"}}>
            <Box display='flex' justifyContent='center' >
              <Typography
                color="textPrimary"
                className={classes.blueUnderlineMobile}
              >
                1000
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center' >
              <Typography
                color="textPrimary"
                style={{ textAlign: "center", fontWeight:"normal", fontSize:"9px", marginTop:"2px"}}
              >
                CONTAINERS.COM
              </Typography>
            </Box>
          </Box>
        </Avatar>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
