import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { THEMES } from 'src/constants';
import Notifications from './Notifications';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from 'src/actions/accountActions';
import { track } from 'src/utils/mixpanel';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  },
  menuButton:{
    color:"white"
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.account.user);

  const handleLogout = async () => {
    try {
      await dispatch(logout());

      track('User Logged Out', {
        pageUrl: window.location.href,
        userState: user?.email ? 'Logged In' : 'Logged Out'
      });
      history.push('/login');
    } catch (error) {
      console.log("some errors");
    }
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Notifications />
        <Button variant='outlined' size='small' onClick={handleLogout}>
          EXIT
        </Button>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
